<template>
  <div class="container">
    <div class="header"><span class="line"></span> 门店管理</div>
    <div class="nav">
    </div>
    <div class="search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :lg="6" :md="8" :sm="12">
            <a-form-item label="门店名称" :labelCol="{lg: {span: 8}, sm: {span: 7}}" :wrapperCol="{lg: {span: 16}, sm: {span: 17} }">
              <a-input v-model="queryParam.fullName" placeholder="请输入门店名称"/>
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="8" :sm="12">
            <a-form-item label="入驻时间" :labelCol="{lg: {span: 8}, sm: {span: 7}}" :wrapperCol="{lg: {span: 16}, sm: {span: 17} }">
              <a-range-picker style="width:230px" @change="onChange" v-model="date" />
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="8" :sm="12">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="handleToSearchEnterprise">查询</a-button>
              <a-button style="margin-left: 8px" icon="reload" @click="resetSearchEnterprise">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <s-table
        style="border-top:1px solid #F0F0F0;margin-top:16px"
        ref="table"
        :columns="raskColumns"
        :data="raskData"
        :rowKey="(record) => record.id"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="detail(record)">查看详情</a>
          <!-- <a-divider type="vertical"/>
          <a @click="binding(record)">人员配置</a> -->
        </span>
        <span slot="time" slot-scope="text, record" v-show="record.startTime">
          {{record.startTime}}~{{record.finishTime}}
        </span>
      </s-table>
    </div>
    <!-- 添加 -->
    <a-modal
      title="人员配置"
      :visible="visibleAdd"
      :confirm-loading="confirmLoadingAdd"
      @ok="handleOkAdd"
      @cancel="handleCancelAdd"
    >
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" @submit="handleOkAdd">
        <a-form-item label="关联任务">
          <a-select style="width: 100%" placeholder="请选择关联任务" showSearch :defaultActiveFirstOption="false" :filterOption="false" @search="handleSearchSelectCompanyRask" @change="handleChangeSelectCompanyRask" v-decorator="['relationNo', {rules:[{ required: true, message: '请选择关联任务' }]}]">
            <a-select-option v-for="item in raskList" :key="item.id" :value="item.id">{{item.title}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="管理用户">
          <a-select style="width: 100%" placeholder="请选择管理用户" showSearch :defaultActiveFirstOption="false" :filterOption="false" @search="handleSearchSelectCompany" mode="multiple" @change="handleChangeSelectCompany" v-decorator="['userIds', {rules:[{ required: true, message: '请选择管理用户', type: 'array' }]}]">
            <a-select-option v-for="item in scopeRateList" :key="item.userId" :value="item.userId">{{item.userName}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="提成比例">
          <a-input
            placeholder="请填写提成比例"
            type="number"
            suffix="%"
            v-decorator="['rebatePoint', { rules: [{ required: true, message: '请填写提成比例!' }] }]"
          />
        </a-form-item>
        <a-form-item label="服务日期">
          <a-range-picker
            placeholder="服务日期"
            v-decorator="['date', { rules: [{ required: true, message: '请选择服务日期!' }] }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import STable from '@/components/table/';
import moment from 'moment'
import {
  enterpriseMerchantList,
  addEnterpriseAccount,
  resetUserPwd,
  freezeEnterpriseAccount,
  unLock,
  raskList,
  taskWorker,
  merchantServiceConfig
} from '@/api/api';
export default {
  components: {
    STable
  },
  data () {
    return {
      raskColumns: [
        {
          title: '门店名称',
          dataIndex: 'fullName',
          align: 'center'
        },
        {
          title: '行业类型',
          dataIndex: 'industryText',
          align: 'center'
        },
        {
          title: '联系人',
          dataIndex: 'chargePerson',
          align: 'center'
        },
        {
          title: '联系电话',
          dataIndex: 'chargePersonPhone',
          align: 'center'
        },
        {
          title: '联系地址',
          dataIndex: 'address',
          align: 'center'
        },
        {
          title: '入驻时间',
          dataIndex: 'createTime',
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: text => text === '10' ? '待处理' : text === '20' ? '平台资料审核' : text === '30' ? '第三方审核' : text === '40' ? '审核成功' : '审核失败',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center'
        }
      ],
      visibleAdd: false,
      confirmLoadingAdd:false,
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      queryParam: {
        enterpriseName: '',
        fullName: '',
        status: undefined,
        startTime: undefined,
        finishTime: undefined
      },
      date: [],
      scopeRateList: [],
      raskList: [],
      record: ''
    }
  },
  methods: {
    // 员工
    handleChangeSelectCompany (value) {
    },
    handleSearchSelectCompany (value) {
      
    },
    // 任务
    handleChangeSelectCompanyRask (value) {
      taskWorker({taskId:value}).then(res => {
        if (res.success) {
          this.scopeRateList = res.result.data
        }
      })
    },
    handleSearchSelectCompanyRask (value) {
      
    },
    // binding
    binding (record) {
      this.record = record
      raskList({enterpriseId:this.record.enterpriseId,status: '20'}).then(res =>{
        if (res.success) {
          this.raskList = res.result.data;
        }
      })
      this.visibleAdd = true
    },
    // 选择时间
    onChange(date, dateString) {
      this.queryParam.startTime = dateString[0]
      this.queryParam.finishTime = dateString[1]
    },
    // 查看详情
    detail (record) {
      window.localStorage.setItem('id',record.id)
      window.localStorage.setItem('submenu','storeManageDetail')
      this.$router.push('/storeManageDetail')
    },
    // 获取列表
    raskData (parameter) {
      return enterpriseMerchantList(Object.assign(parameter,this.queryParam,{status: '40'})).then(res => {
        return res.result
      })
    },
    // 查询    
    handleToSearchEnterprise() {
      this.$refs.table.refresh(true)
    },
    // 重置
    resetSearchEnterprise() {
      this.queryParam = {
        enterpriseName: '',
        fullName: '',
        status: undefined,
        startTime: undefined,
        finishTime: undefined
      };
      this.date = []
      this.handleToSearchEnterprise();
    },
    // 添加
    handleOkAdd (e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoadingAdd = true
          let params = {
            merchantId: this.record.id,
            rebatePoint:(values.rebatePoint/100).toFixed(2),
            serviceBeginDate:moment(values.date[0]).format('YYYY-MM-DD'),
            serviceEndDate:moment(values.date[1]).format('YYYY-MM-DD'),
            users: [],
            relationNo: values.relationNo,
            relationNoTitle: ''
          }
          this.raskList.forEach(item=>{
            if (item.id===params.relationNo) {
              params.relationNoTitle = item.title
            }
          })
          this.scopeRateList.forEach(item=>{
            values.userIds.forEach(user=>{
              if (item.userId===user) {
                params.users.push({
                  relationId:user,
                  relationName:item.userName
                })
              }
            })
          })
          merchantServiceConfig(params).then(res=> {
            this.confirmLoadingAdd = false
            if (res.success) {
              this.$message.success(res.message)
              this.handleToSearchEnterprise()
              this.handleCancelAdd()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      });
    },
    handleCancelAdd () {
      this.form.resetFields()
      this.visibleAdd = false
    },
    // 冻结
    freeze (record) {
      let that = this
      this.$confirm({
        title: '确定冻结' + record.userName + '吗?',
        onOk() {
          return new Promise((resolve, reject) => {
            freezeEnterpriseAccount({id:record.id}).then(res => {
            if (res.success) {
              that.$message.success(res.message)
              that.$refs.raskTable.refresh(true)
              resolve()
            } else {
              that.$message.error(res.message)
              resolve()
            }
          })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
    // 解冻
    refreeze (record) {
      let that = this
      this.$confirm({
        title: '确定解冻' + record.userName + '吗?',
        onOk() {
          return new Promise((resolve, reject) => {
            unLock({id:record.id}).then(res => {
              if (res.success) {
                that.$message.success(res.message)
                that.$refs.raskTable.refresh(true)
                resolve()
              } else {
                that.$message.error(res.message)
                resolve()
              }
            })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
    // 重置密码
    reset (record) {
      let that = this
      this.$confirm({
        title: '确定重置' + record.userName + '的密码?',
        onOk() {
          return new Promise((resolve, reject) => {
            resetUserPwd({id:record.id}).then(res => {
              if (res.success) {
                that.$message.success(res.message)
                that.$refs.raskTable.refresh(true)
                resolve()
              } else {
                that.$message.error(res.message)
                resolve()
              }
            })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
  }
}
</script>
<style scoped lang="less">
.container{
  background: #FFFFFF;
  border-radius: 4px;
  height: 935px;
  padding: 40px;
}
.header{
  .line{
    width: 2px;
    height: 18px;
    background: #347CF5;
    margin-right: 9px;
  }
  color: #333333;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.nav{
  margin: 10px 0 0 0;
  .common{
    color: #999999;
    margin: 0;
  }
}
.opt{
  margin: 20px 0 30px 0;
}
.search-wrapper{
  margin: 30px 0;
}
</style>